import './styles.scss'
import { useHistory, useParams } from 'react-router-dom'
import React, { useContext } from 'react'
import { VendorContext } from '../../../context/VendorContext'
import { IonPage, IonHeader, IonButtons, IonSearchbar, IonButton, IonToolbar, IonTitle, IonContent, IonList, IonItem, IonCol, IonThumbnail, IonLabel, IonRow, IonImg, IonCard, IonText, IonIcon, IonFooter, IonBadge, IonTabBar, IonTabButton, IonTabs } from '@ionic/react';
import BackButton from '../../../components/BackButton';

import { navigateCircleOutline, timeOutline, callOutline, logoWhatsapp, documentOutline, logoInstagram } from 'ionicons/icons';
import Tabs from '../../../components/Tabs/Index';

export default function ShowVendor(props: any) {

   const router = useHistory()
   const { id } = useParams<any>()



   console.log(id)

   const { vendors, setVendors, updateVendorContext, setUpdateVendorContext, IsVendorsLoading, setIsVendorsLoading } = useContext(VendorContext)

   const vendor = vendors ? vendors.find((vendor: any) => vendor._id == id) : null

   console.log('vendor', vendor)

   if (!vendor) return <h2>Loading</h2>
   else return (
      <IonPage className="mobile-show-vendor">

         <IonHeader>
            <IonToolbar color='primary'>
               <BackButton />

               <IonTitle>
                  Detalhes
               </IonTitle>


            </IonToolbar>

         </IonHeader>
         <IonContent color="transparent" className="ion-padding-horizontal" fullscreen >

            <IonRow className="ion-align-items-center ion-margin-top ion-padding-horizontalx">
               <IonCol >
                  <h1>{vendor.name}</h1>
                  <IonText className="ion-margin-top">Beleza e Estética</IonText>
               </IonCol>
               <IonCol size="3">

                  <IonThumbnail>
                     <img src={vendor.image.logo} />
                  </IonThumbnail>


               </IonCol>
            </IonRow>

            <IonRow>
               <IonCol>
                  <IonList lines="full">
                     <IonItem className="ion-no-padding">
                        <IonIcon icon={navigateCircleOutline} slot="start" />
                        <IonLabel className="ion-text-wrap">
                           <strong>Endereço: </strong>
                           {vendor.address || 'Não informado'}
                        </IonLabel>
                     </IonItem>
                     <IonItem className="ion-no-padding">
                        <IonIcon icon={timeOutline} slot="start" />
                        <IonLabel className="ion-text-wrap">
                           <strong>Horário de funcionamento: </strong>
                           {vendor.openTime || 'Não informado'}
                        </IonLabel>
                     </IonItem>
                     <IonItem className="ion-no-padding">
                        <IonIcon icon={callOutline} slot="start" />
                        <IonLabel className="ion-text-wrap">
                           <strong>Telefone: </strong>
                           {vendor.phone || 'Não informado'}
                        </IonLabel>
                     </IonItem>
                     <IonItem className="ion-no-padding">
                        <IonIcon icon={logoWhatsapp} slot="start" />
                        <IonLabel className="ion-text-wrap">
                           <strong>WhatsApp: </strong>
                           {vendor.phone || 'Não informado'}
                        </IonLabel>
                     </IonItem>
                     <IonItem className="ion-no-padding">
                        <IonIcon icon={logoInstagram} slot="start" />
                        <IonLabel className="ion-text-wrap">
                           <strong>Instagram: </strong>
                           {vendor.social.instagram || 'Não informado'}
                        </IonLabel>
                     </IonItem>
                     <IonItem className="ion-no-padding">
                        <IonIcon icon={documentOutline} slot="start" />
                        <IonLabel className="ion-text-wrap">
                           <strong>Descrição: </strong>
                           {vendor.description || 'Não informado'}
                        </IonLabel>
                     </IonItem>
                  </IonList>


               </IonCol>

            </IonRow>
            <IonLabel>
            </IonLabel>

         </IonContent >

         <IonFooter>
            <Tabs currentPath={props.match.url} />
         </IonFooter>

      </IonPage >
   )
}