import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import { VendorsProvider } from './context/VendorContext'


// default ionic css
import '@ionic/react/css/core.css';


ReactDOM.render(
  <React.StrictMode>
    <VendorsProvider>
      <App />
    </VendorsProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
