import React from "react";
import { useHistory } from 'react-router-dom'
import { IonButton, IonIcon } from "@ionic/react";
import { chevronBack } from "ionicons/icons";

const BackButton: React.FC<any> = () => {

   const router = useHistory();

   return <IonButton color='light' slot='start' fill="clear" size="small" onClick={() => router.goBack()}>
      <IonIcon icon={chevronBack} /> <span style={{ fontSize: '0.85em', fontWeight: 800 }}>Voltar</span>
   </IonButton>
}



export default BackButton;
