import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons } from '@ionic/react';
import React from 'react';
// import './Home.css';
import { NavButtons } from '../components/NavButtons';

const Home: React.FC = () => {
   return (
      <IonPage>
         <IonHeader>
            <IonToolbar>
               <IonTitle>HOME</IonTitle>
               <IonButtons slot="end">
                  <NavButtons />
               </IonButtons>
            </IonToolbar>
         </IonHeader>
         <IonContent fullscreen>
         </IonContent>
      </IonPage>
   );
};

export default Home;