import React, { useContext } from 'react'
import './styles.scss'

import { Router, useHistory } from 'react-router-dom';

import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonRow, IonCard, IonList, IonItem, IonThumbnail, IonLabel, IonIcon, IonMenuButton, IonButton, IonButtons, IonMenuToggle, IonApp, IonListHeader, IonMenu, IonFooter } from '@ionic/react'

// Contexts
import { VendorContext } from '../../context/VendorContext';
import Tabs from '../../components/Tabs/Index';

export default function Profile(props: any) {

   const router = useHistory();

   const { vendors, setVendors, updateVendorContext, setUpdateVendorContext, IsVendorsLoading, setIsVendorsLoading } = useContext(VendorContext)

   if (!vendors) return <h2>Loading</h2>

   else return (
      <IonPage className="mobile-home">
         <IonHeader>

            <IonToolbar color='primary'>

               <IonTitle className="ion-text-center">Profile Page</IonTitle>
            </IonToolbar>


         </IonHeader>
         <IonContent color="light" class='center' fullscreen>

            <h2>Profile page</h2>


         </IonContent >

         <IonFooter>
            <Tabs currentPath={props.match.url} />
         </IonFooter>
      </IonPage >

   )
}