import React, { useContext, useState } from 'react'
import './styles.scss'

import { Router, useHistory } from 'react-router-dom';

import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonRow, IonCard, IonList, IonItem, IonThumbnail, IonLabel, IonIcon, IonMenuButton, IonButton, IonButtons, IonMenuToggle, IonApp, IonListHeader, IonMenu, IonFooter, IonSearchbar } from '@ionic/react'




// Contexts
import { VendorContext } from '../../context/VendorContext';
import Tabs from '../../components/Tabs/Index';

export default function Home(props: any) {

   const router = useHistory();

   const { vendors, setVendors, updateVendorContext, setUpdateVendorContext, IsVendorsLoading, setIsVendorsLoading } = useContext(VendorContext)

   const [searchText, setSearchText] = useState('')



   function verifyIfIncludes(object: any, fields: any, value: any) {
      return fields.some((field: any) =>
         object[field].toLowerCase().includes(value.toLowerCase())
      )
   }





   let filteredVendors = vendors ?
      vendors.filter((vendor: any) => {

         return verifyIfIncludes(vendor, ["name", "description"], searchText)
      })
      // vendors.filter((vendor: any) => {

      //    return vendor.name.toLowerCase().includes(searchText.toLowerCase())

      // })
      : vendors

   console.log(filteredVendors)



   if (!vendors) return <h2>Loading</h2>

   else return (
      <IonPage className="mobile-home">
         <IonHeader>


            <IonToolbar color='primary'>

               <IonTitle className="ion-text-center">Uay</IonTitle>
            </IonToolbar>


         </IonHeader>
         <IonContent color="light" class='center' fullscreen>

            <IonSearchbar
               value={searchText}
               onIonChange={(e: any) => setSearchText(e.detail.value!)}
               placeholder="Pesquisar...">
            </IonSearchbar>



            <IonList lines="none">
               {filteredVendors && filteredVendors?.map((vendor: any, index: any) =>
                  <IonItem onClick={() => router.push(`/vendor/${vendor._id}`)} key={index}>
                     <IonThumbnail slot="start">
                        <img src={vendor.image.logo} />
                     </IonThumbnail>
                     <IonLabel>

                        <h2>{vendor.name}</h2>
                        <p>{vendor.description}</p>
                        <p>{vendor.phone}</p>
                     </IonLabel>
                  </IonItem>
               )}
            </IonList>
         </IonContent >

         <IonFooter>
            {/* <Tabs currentPath={props.match.url} /> */}
         </IonFooter>
      </IonPage >

   )
}