import { IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonMenu, IonRouterOutlet, IonRow, IonText, IonTitle, IonToolbar } from '@ionic/react';
import { briefcase, cash, home, people, person, chatbubbles } from 'ionicons/icons';
import React from 'react';
import { useHistory } from 'react-router';
// import { UserContext } from '../context/UserContext';
// import { VendorContext } from '../../context/VendorContext';
// import auth from '../services/auth';

const Tabs: React.FC<any> = ({ currentPath }) => {

  const menus: any = [
    {
      name: 'Home',
      icon: home,
      path: '/'
    },
    {
      name: 'Chat',
      icon: chatbubbles,
      path: '/chat'
    },
    {
      name: 'Perfil',
      icon: person,
      path: '/profile'
    }

  ]

  // const { user } = React.useContext(UserContext);

  const router = useHistory()

  // if (user?.type === "client") return (
  return (
    <IonRow style={{ background: '#463697', borderTop: '1px solid #CCCCCC', paddingTop: '5px', textAlign: 'center' }}>


      {
        menus ? menus.map((menu: any, index: any) =>
          <IonCol key={index} onClick={() => router.push(menu.path)} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <IonIcon color={currentPath === menu.path ? "secondary" : "light"} size="large" icon={menu.icon} />
            <IonLabel color={currentPath === menu.path ? "secondary" : "light"}><small>{menu.name}</small></IonLabel>
          </IonCol>
        )
          : null
      }


      {/* <IonCol onClick={() => router.push('/')} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <IonIcon color={currentPath === "/client" ? "secondary" : "light"} size="large" icon={home} />
        <IonLabel color={currentPath === "/client" ? "secondary" : "light"}><small>Início</small></IonLabel>
      </IonCol> */}

      {/* <IonCol onClick={() => router.push('/client/jobs')} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <IonIcon color={currentPath.includes("/client/jobs") ? "secondary" : "light"} size="large" icon={briefcase} />
        <IonLabel color={currentPath.includes("/client/jobs") ? "secondary" : "light"}><small>Servicios</small></IonLabel>
      </IonCol>

      <IonCol onClick={() => router.push('/client/freelancers')} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <IonIcon color={currentPath.includes("/client/freelancers") ? "secondary" : "light"} size="large" icon={people} />
        <IonLabel color={currentPath.includes("/client/freelancers") ? "secondary" : "light"}><small>Freelancers</small></IonLabel>
      </IonCol>

      <IonCol onClick={() => router.push("/messages")} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <IonIcon color={currentPath === "/messages" ? "secondary" : "light"} size="large" icon={chatbubbles} />
        <IonLabel color={currentPath === "/messages" ? "secondary" : "light"}><small>Mensajes</small></IonLabel>
      </IonCol>

      <IonCol onClick={() => router.push("/profile")} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <IonIcon color={currentPath === "/profile" ? "secondary" : "light"} size="large" icon={person} />
        <IonLabel color={currentPath === "/profile" ? "secondary" : "light"}><small>Perfil</small></IonLabel>
      </IonCol> */}


    </IonRow>
  );



};

export default Tabs;