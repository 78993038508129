import React from 'react';
import { createContext, useEffect, useState } from 'react'
import api from '../services/api'

// import api from '../services/api';
// import auth from '../services/auth';

export const VendorContext = createContext<any>({})

export function VendorsProvider(props: any) {

  console.log('abriu use context')

  const [vendors, setVendors] = useState<any>(false);
  const [updateVendorContext, setUpdateVendorContext] = useState<any>(false);
  const [IsVendorsLoading, setIsVendorsLoading] = React.useState<any>(true);

  useEffect(() => {
    let flag = true
    async function fetchData() {
      try {
        const vendor = await api.get('/vendor')
        if (flag) setVendors(vendor.data)
        if (flag) setUpdateVendorContext(false)
        if (flag) setIsVendorsLoading(false)
      } catch (error) {
        console.log("Erro:", error?.response?.data);
      }
    } fetchData()
    return () => { flag = false }
  }, [updateVendorContext])

  return (
    <VendorContext.Provider value={{
      vendors,
      IsVendorsLoading,
      setVendors,
      setIsVendorsLoading,
      setUpdateVendorContext
    }}>
      {props.children}
    </VendorContext.Provider>
  )
}