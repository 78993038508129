import React, { useContext, useEffect, useState } from 'react'
import { io } from "socket.io-client";
import { IonCard, IonButton, IonCol, IonContent, IonHeader, IonInput, IonItem, IonLabel, IonPage, IonRow, IonText, IonTitle, IonToolbar, IonGrid, IonBadge, IonAvatar, IonFooter, IonThumbnail, IonIcon } from '@ionic/react';

import './chat.scss'
import { send } from 'ionicons/icons';

// import { UserContext } from '../../../context/UserContext'

// import api from '../../../services/api'

// import BackButton from '../../../components/BackButton';
// import Tabs from '../../components';

import { VendorContext } from '../../context/VendorContext';
import { useHistory } from 'react-router-dom';
import BackButton from '../../components/BackButton';
import Tabs from '../../components/Tabs/Index';
import api from '../../services/api';
import formatDateHour from '../../utils/formatDateHour';
import local from '../../services/localStorage';
import generateUUID from '../../utils/generateUUID';


const socket = io(process.env.REACT_APP_PROD_API || "http://localhost:3070", { autoConnect: false });


export default function Chat(props: any) {

   // const { user, setUpdateUserContext } = React.useContext(UserContext);
   const [user, setUser] = useState<any>(false)
   // const [messages, setMessages] = useState<any>([])

   const chatForm = React.useRef<any>(null);
   const messagesEnd = React.useRef<any>(null);
   const [socketId, setSocketId] = useState<any>('60aa5fbe173bd52612450c24') // city tombos ID
   const [chat, setChat] = useState<any>([])


   const router = useHistory();

   const { vendors, setVendors, updateVendorContext, setUpdateVendorContext, IsVendorsLoading, setIsVendorsLoading } = useContext(VendorContext)
   React.useEffect(() => {

      async function fetchMessages() {


         // 1 check if user has been logged
         const loggedUser = await local.getItem("user")

         if (loggedUser) {

            setUser(loggedUser)
            console.log('user created and logged in', loggedUser)
         }
         else {
            // 2 log in the user
            // generate UUID for user if he doesn't have one yet
            const userID = await generateUUID()
            let loggedUser = await local.setItem("user", userID)
            setUser(loggedUser)
            console.log('user created and logged in', userID)

         }


         let getChat: any = await api.get(`/message/${socketId}`)
         setChat(getChat.data)
         // setUserReceiver(user.type == 'freelancer' ? getChat.data?.client?.name : getChat.data?.freelancer?.name)
      } fetchMessages()
   }, [
      socketId,
      // userReceiver,
   ])

   React.useEffect(() => {
      async function joinSocket() {
         // if (canCreate) {
         if (socketId) {
            socket.connect()
            // socket.on("connect", async () => {
            //    console.log('connected', socket.id); // true
            // });

            console.log(socket.id)

            socket.emit("createChat", socketId)
            socket.on(socketId, (message: any) => {
               setChat((currentChat: any) => ({
                  messages: [...currentChat.messages, message]
               }))
            })
         }
         // }
      } joinSocket()
      return () => { socket.disconnect() }
   }, []
      // [user, socketId, ]
   )
   React.useEffect(() => {
      async function updateMessages() {
         if (socketId && chat) {
            await api.patch(`/message/${socketId}`, { messages: chat.messages })
            messagesEnd.current?.scrollIntoView({ behavior: "smooth" })
         }
      } updateMessages()
   }, [chat])

   async function handleSubmit(e: any) {
      e.preventDefault();
      let message = {
         text: e.target.message.value,
         user: user,
         date: new Date().toString(),
         socketId: socketId
      }

      console.log('submit mesage', message)

      socket.emit(socketId, message)

      chatForm?.current?.reset();
   }


   return (
      <IonPage>
         <IonHeader>
            <IonToolbar color='primary'>
               <BackButton />
               {/* <div className='d-flex align-items-center'> */}
               <div >
                  {/* <IonAvatar className="d-flex align-items-center" style={{ marginRight: '.8rem' }}>
                     <img className="img-fluid " style={{ padding: '.3rem', borderRadius: '50%', width: '80%', objectFit: 'cover', objectPosition: '50% 50%' }} src={"https://ionicframework.com/docs/demos/api/avatar/avatar.svg"} />
                  </IonAvatar> */}
                  <h5 className="ion-no-margin"><strong>Conversa - Tombos MG</strong></h5>

                  {/* <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                     <p className="ion-no-margin"><strong>Tombos MG</strong></p>
                  </div> */}
               </div>
            </IonToolbar>
         </IonHeader>


         <IonContent className="chat-mobile-page">

            {chat?.messages && chat?.messages?.map((message: any, index: any) =>
               <IonRow
                  key={index}
                  className={`justify-content-${message.user == user ? 'end' : 'start'}`}

               >
                  <IonCol size="10">
                     <IonCard key={message} color={message.user == user ? 'primary' : 'clear'}
                        className="ion-padding">
                        <IonText>
                           {message?.text}<br />
                           <small>
                              {/* Enviado por <strong>{message?.user}</strong> em */}
                              <strong>{formatDateHour(message?.date)}</strong>
                           </small>
                        </IonText>
                     </IonCard>
                  </IonCol>
               </IonRow>
            )}

            <div ref={messagesEnd}></div>

         </IonContent>

         <form ref={chatForm} onSubmit={handleSubmit}>
            <IonItem color="light" lines="none">
               <IonInput required name="message" placeholder="Mensagem..." autofocus={true}></IonInput>
               <IonButton style={{ width: '3rem' }} type="submit" >
                  <IonIcon icon={send} />

               </IonButton>
            </IonItem>
         </form>

         <IonFooter>
            <Tabs currentPath={props.match.url} />
         </IonFooter>
      </IonPage>


   )
}
