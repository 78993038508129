import { Route, BrowserRouter, withRouter } from 'react-router-dom';
import { useLocation } from 'react-router-dom'



import {
  IonApp,
  IonContent,
  IonFooter,
  IonRouterOutlet,
  setupConfig,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.scss'

// Pages
import Home from './pages/home';
import ShowVendor from './pages/vendors/show';
import Home2 from './pages/testMenu';


import Chat from './pages/chat';
import Profile from './pages/profile';
import Tabs from './components/Tabs/Index';
import React from 'react';




function App(props: any) {

  // let location = {}
  // location = useLocation()

  console.log('props', window.location.pathname)
  return (
    <IonApp>
      <IonContent >
        <BrowserRouter>
          <IonReactRouter>
            <IonRouterOutlet>
              <Route path="/profile" exact component={Profile} />
              <Route path="/" exact component={Home} />
              <Route path="/vendor/:id" exact component={ShowVendor} />
              <Route path="/home2" exact component={Home2} />
              <Route path="/chat" exact component={Chat} />
            </IonRouterOutlet>
          </IonReactRouter>
        </BrowserRouter>
      </IonContent>
      <IonFooter>
        <Tabs currentPath={window.location.pathname} />
      </IonFooter>


    </IonApp>
  );
}

export default App;
